import React from 'react';
import { bool, string } from 'prop-types';
import { loadable } from 'nordic/lazy';
import { RecommendationsList, RecommendationsListPads } from '@recommendations-fe/list';
import RecommendationsIconFactory from './icon-factory';
import ContextTemplate from './polycard-context';

const Polycard = loadable(() => import('../polycard/polycard'));

Polycard.preload();

const RecommendationsCardHorizontal = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardHorizontal,
});

RecommendationsCardHorizontal.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const CardTemplate = cardProps =>
    isPolycard ? (
      <Polycard polycard={{ ...cardProps }} className="recos-polycard poly-card--mobile" />
    ) : (
      <RecommendationsCardHorizontal {...cardProps} />
    );

  return (
    <RecommendationsList
      ListComponent={RecommendationsListPads}
      listType="pads"
      Card={CardTemplate}
      IconFactory={RecommendationsIconFactory}
      Context={ContextTemplate}
      contextProps={{
        type: 'list',
        thousandSeparator,
        decimalSeparator,
      }}
      isPolycard={isPolycard}
      {...props}
    />
  );
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
};

export default Recommendations;
