import React from 'react';
import { node, shape, string } from 'prop-types';
import { loadable } from 'nordic/lazy';

const PolycardContext = loadable(() => import('../polycard/polycardContext'));

const ContextTemplate = ({ contextValue, type, thousandSeparator, decimalSeparator, children }) => (
  <PolycardContext
    contextValue={{ ...contextValue, type }}
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
  >
    {children}
  </PolycardContext>
);

ContextTemplate.propTypes = {
  children: node,
  contextValue: shape({}),
  type: string,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
};

export default ContextTemplate;
